import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AccordionContent, AccordionTitle, Icon } from 'semantic-ui-react';

import Toggle from '../../../../../common/Toggle/Toggle.component';
import styles from './AuditorAccordion.module.scss';

const AuditorAccordion = ({ title, data, active, index, onTitleClick, filter, auditorFilters }) => {
  const dispatch = useDispatch();

  return (
    <>
      <AccordionTitle active={active} className={styles.accordionTitle} index={index} onClick={onTitleClick}>
        <span className={styles.accordionTitleHeader}>
          {title}
        </span>
        <Icon className={styles.accordionArrow} name={active ? 'angle down' : 'angle right'}/>
      </AccordionTitle>
      <AccordionContent active={active}>
        <div className={styles.accordionContent}>
          {data?.map(item => (
            <div key={item.value} className={styles.sliderContainer}>
              <span key={item.value}>
                {item.title}
              </span>
              <Toggle checked={auditorFilters[filter]?.includes(item.value)} onChange={() => dispatch({
                type: 'TOGGLE_AUDITORS_FILTER',
                payload: {
                  filter,
                  value: item.value,
                },
              })}
              />
            </div>
          ))}
        </div>
      </AccordionContent>
    </>
  );
};

export default AuditorAccordion;

AuditorAccordion.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  auditorFilters: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })),
  filter: PropTypes.string,
  onTitleClick: PropTypes.func,
};

AuditorAccordion.defaultProps = {
  active: false,
  auditorFilters: {},
  data: [],
  filter: '',
  index: 0,
  onTitleClick: () => {},
};

import { createAction } from 'redux-actions';

import * as actionTypes from './actionTypes';

export const toggleMapView = createAction(actionTypes.TOGGLE_MAP_VIEW);
export const toggleAuditors = createAction(actionTypes.TOGGLE_AUDITORS);
export const toggleAuditorsFilter = createAction(actionTypes.TOGGLE_AUDITORS_FILTER, payload => payload);
export const setInitialAuditorsFilter = createAction(
  actionTypes.SET_INITIAL_AUDITORS_FILTER,
  payload => payload,
);

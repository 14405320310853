import { handleActions } from 'redux-actions';

import { toggleArrayItem } from '../../utils/array';
import * as actions from './actionCreators';
import initialState from './initialState';

export default {
  googleMapFeature: handleActions(
    {
      [actions.toggleMapView]: state => ({
        ...state,
        enabled: !state.enabled,
      }),
      [actions.toggleAuditors]: state => ({
        ...state,
        showAuditors: !state.showAuditors,
      }),
      [actions.toggleAuditorsFilter]: (state, { payload }) => ({
        ...state,
        auditorFilters: {
          ...state.auditorFilters,
          [payload.filter]: toggleArrayItem(
            state.auditorFilters[payload.filter],
            payload.value,
          ),
        },
      }),
      [actions.setInitialAuditorsFilter]: (state, { payload }) => ({
        ...state,
        auditorFilters: {
          ...state.auditorFilters,
          [payload.filter]: payload.value,
        },
      }),
    },
    initialState.googleMapFeature,
  ),
};

import DeckGL from '@deck.gl/react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';
import React from 'react';

const MapRenderer = ({ layers, updateViewState, children }) => (
  <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
    <DeckGL
      controller={{ dragRotate: false }}
      initialViewState={{
        longitude: -0.1278,
        latitude: 51.5074,
        zoom: 8,
      }}
      layers={layers}
      onViewStateChange={updateViewState}
    >
      <Map
        reuseMaps
        defaultCenter={{ lat: 51.5074, lng: -0.1278 }}
        defaultZoom={8}
        mapId="71310bce4b4e4fba"
      />
      {children}
    </DeckGL>
  </APIProvider>
);

export default MapRenderer;

MapRenderer.defaultProps = {
  children: null,
  layers: [],
  updateViewState: () => {},
};

MapRenderer.propTypes = {
  children: PropTypes.node,
  layers: PropTypes.array,
  updateViewState: PropTypes.func,
};

import { WebMercatorViewport } from 'deck.gl';
import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { auditorsAPI, auditsAPI } from '../../../../api';
import { mapFeatureAuditorFiltersSelector, mapFeatureEnabledSelector, mapFeatureShowAuditorsSelector } from '../../../../state/auditTableView/selectors';
import { parse } from '../../../../utils/queryString';
import { createIconLayer, LAYER_ID, mapAuditorsByType } from '../helpers';
import AuditorMapControl from '../MapControls/AuditorMapControl/AuditorMapControl.component';
import MapRenderer from '../MapRenderer/MapRenderer.component';
import MapTooltip from '../MapTooltip/MapTooltip.component';
import styles from './AuditsMap.module.scss';

const parseQueryParams = queryStringValue => {
  const currentQuery = parse(queryStringValue);
  const { page, sort, order, limit, ...filterValues } = currentQuery;
  return filterValues || {};
};

const AuditsMap = () => {
  const location = useLocation();
  const mapView = useSelector(mapFeatureEnabledSelector, shallowEqual);
  const showAuditors = useSelector(mapFeatureShowAuditorsSelector, shallowEqual);
  const auditorFilters = useSelector(mapFeatureAuditorFiltersSelector, shallowEqual);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [tooltipFixed, setTooltipFixed] = useState(false);

  const { data: mapAudits } = useQuery(
    ['auditsMap', location.search],
    async () => {
      const res = await auditsAPI.fetchAllForMap(parseQueryParams(location.search));
      return res.audits.filter(d => d.site_coordinates);
    },
    {
      enabled: mapView,
      staleTime: 5 * 60 * 1000,
    },
  );

  const { data: mapAuditors } = useQuery(
    'fetchAuditorsForMap',
    async () => {
      const data = await auditorsAPI.fetchAllForMap();
      return mapAuditorsByType(data.items);
    },
    {
      enabled: mapView && showAuditors,
      staleTime: 15 * 60 * 1000,
    },
  );

  const enableAuditorData = !!tooltipInfo?.object?.id && tooltipInfo.layerId === LAYER_ID.AUDITORS;

  const { data: auditorTooltipInfo } = useQuery(
    ['auditorTooltip', tooltipInfo?.object?.id],
    () => auditorsAPI.fetchDetailsForMap({ id: tooltipInfo?.object?.id }),
    {
      enabled: enableAuditorData,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  );

  const enableAuditsData = !!tooltipInfo?.object?.id && tooltipInfo.layerId === LAYER_ID.AUDITS;

  const { data: auditsTooltipInfo } = useQuery(
    ['auditsTooltip', tooltipInfo?.object?.id],
    () => auditsAPI.fetchAuditTooltip({ id: tooltipInfo?.object?.id }),
    {
      enabled: enableAuditsData,
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  );

  const handleHover = useCallback(info => {
    if (info.object && !tooltipFixed) {
      setTooltipInfo({
        object: info.object,
        x: info.x,
        y: info.y,
        layerId: info.layer.id,
      });
    } else if (!info.object && !tooltipFixed) {
      setTooltipInfo(null);
    }
  }, [tooltipFixed]);

  const handleClick = useCallback(info => {
    if (!info.object?.id) return;
    if (info.object.id === tooltipInfo?.object?.id) {
      setTooltipFixed(prev => !prev);
    } else {
      setTooltipInfo({
        object: info.object,
        x: info.x,
        y: info.y,
        layerId: info.layer.id,
      });
      setTooltipFixed(true);
    }
  }, [tooltipInfo?.object?.id]);

  const filteredAuditors = useMemo(() => (
    mapAuditors?.filter(a => (
      auditorFilters?.patches?.length && auditorFilters.patches.includes(a.patchId)
      && auditorFilters?.ageRanges?.length && auditorFilters.ageRanges.includes(a.ageRange)
      && auditorFilters?.types?.length && auditorFilters.types.includes(a.type)
    ))
  ), [mapAuditors, auditorFilters]);

  const handleMapViewStateChange = ({ viewState }) => {
    if (!tooltipInfo) return;
    const { lng, lat } = tooltipInfo.object.site_coordinates || tooltipInfo.object.home_coordinates;
    const [x, y] = new WebMercatorViewport(viewState).project([lng, lat]);

    setTooltipInfo(prev => ({
      ...prev,
      x,
      y: y + 20,
    }));
  };

  const auditsLayer = useMemo(
    () => createIconLayer({ id: LAYER_ID.AUDITS,
      items: mapAudits,
      layerProps: { onHover: handleHover, onClick: handleClick } }),
    [mapAudits, handleHover, handleClick],
  );
  const auditorsLayer = useMemo(() => createIconLayer({ id: LAYER_ID.AUDITORS,
    items: filteredAuditors,
    layerProps: {
      getPosition: a => [a.coordinates.lng, a.coordinates.lat, 0],
      getColor: () => [255, 0, 0, 255],
      visible: showAuditors,
      onHover: handleHover,
      onClick: handleClick,
    } }), [filteredAuditors, showAuditors, handleHover, handleClick]);

  const layers = [auditsLayer, auditorsLayer];

  return (
    <div className={styles.mapContainer}>
      <MapRenderer layers={layers} updateViewState={handleMapViewStateChange}>
        {(auditorTooltipInfo || auditsTooltipInfo) && (
          <MapTooltip
            data={{
              ...tooltipInfo,
              ...auditorTooltipInfo,
              ...auditsTooltipInfo,
            }}
            fixed={tooltipFixed}
            onClose={() => {
              setTooltipInfo(null);
              setTooltipFixed(false);
            }}
          />
        )}
      </MapRenderer>
      <AuditorMapControl />
    </div>
  );
};

export default AuditsMap;

import { createSelector } from 'reselect';

export const mapFeatureSelector = state => state.googleMapFeature;

export const mapFeatureEnabledSelector = createSelector(
  mapFeatureSelector,
  mapFeature => mapFeature.enabled,
);

export const mapFeatureShowAuditorsSelector = createSelector(
  mapFeatureSelector,
  mapFeature => mapFeature.showAuditors,
);

export const mapFeatureAuditorFiltersSelector = createSelector(
  mapFeatureSelector,
  mapFeature => mapFeature.auditorFilters,
);

import React, { memo, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Accordion } from 'semantic-ui-react';

import { entitiesAPI } from '../../../../../api';
import { mapFeatureAuditorFiltersSelector, mapFeatureShowAuditorsSelector } from '../../../../../state/auditTableView/selectors';
import { toggleArrayItem } from '../../../../../utils/array';
import Toggle from '../../../../common/Toggle/Toggle.component';
import { AUDITOR_AGE_RANGES, AUDITOR_TYPES } from '../../helpers';
import AuditorAccordion from './AuditorAccordion/AuditorAccordion.component';
import styles from './AuditorMapControl.module.scss';

const AuditorMapControl = memo(() => {
  const dispatch = useDispatch();
  const showAuditors = useSelector(mapFeatureShowAuditorsSelector, shallowEqual);
  const auditorFilters = useSelector(mapFeatureAuditorFiltersSelector, shallowEqual);
  const [activeIndexes, setActiveIndexes] = useState([]);

  const handleToggleAuditors = useCallback(() => {
    dispatch({
      type: 'TOGGLE_AUDITORS',
    });
  }, [dispatch]);

  const handleAccordionChange = (e, { index }) => {
    e.stopPropagation();
    setActiveIndexes(prevIndexes => toggleArrayItem(prevIndexes, index));
  };

  const { data: patches } = useQuery(
    ['mapPatches'],
    async () => {
      const res = await entitiesAPI.fetchAllv1('patches');
      dispatch({
        type: 'SET_INITIAL_AUDITORS_FILTER',
        payload: {
          filter: 'patches',
          value: res.items.map(p => p.id),
        },
      });
      return res.items.map(p => ({ title: p.name, value: p.id }));
    },
    {
      staleTime: Infinity,
      enabled: showAuditors,
    },
  );

  const auditorTypes = [
    { title: 'Home', value: AUDITOR_TYPES.HOME },
    { title: 'Term', value: AUDITOR_TYPES.TERM },
    { title: 'Other', value: AUDITOR_TYPES.OTHER },
  ];

  const auditorAgeRanges = [
    { title: '18 and under', value: AUDITOR_AGE_RANGES['<18'] },
    { title: '18 to 19', value: AUDITOR_AGE_RANGES['18-19'] },
    { title: '20 to 25', value: AUDITOR_AGE_RANGES['20-25'] },
    { title: '26+', value: AUDITOR_AGE_RANGES['>26'] },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.sliderContainer}>
        <span className={styles.mapControlLabel}>
Auditors
        </span>
        <Toggle checked={showAuditors} onChange={handleToggleAuditors} />
      </div>
      { showAuditors && (
        <Accordion exclusive={false}>
          <AuditorAccordion active={activeIndexes.includes(0)} auditorFilters={auditorFilters} data={patches} filter={'patches'} index={0} title="Patches" onTitleClick={handleAccordionChange}/>
          <AuditorAccordion active={activeIndexes.includes(1)} auditorFilters={auditorFilters} data={auditorAgeRanges} filter={'ageRanges'} index={1} title="Age" onTitleClick={handleAccordionChange}/>
          <AuditorAccordion active={activeIndexes.includes(2)} auditorFilters={auditorFilters} data={auditorTypes} filter={'types'} index={2} title="Address" onTitleClick={handleAccordionChange}/>
        </Accordion>
      )}
    </div>
  );
});

export default AuditorMapControl;

AuditorMapControl.displayName = 'AuditorMapControl';

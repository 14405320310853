import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../MapTooltip.module.scss';

const AUDIT_RESULT = {
  PASS: 'pass',
  FAIL: 'fail',
};

const AUDIT_STATUS = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
};

const AuditContent = ({ content }) => {
  const { item_to_order = '', deadline_date = '', end_date = '', audit_history = [], object = {}, visit_info = '', auditor_name = '' } = content || {};
  const isAssigned = object?.status === AUDIT_STATUS.ASSIGNED;

  return (
    <div className={styles.tooltipContent}>
      <p>
        <strong>
Item to Order:
        </strong>
        {item_to_order}
      </p>
      <p>
        <strong>
Audit end date:
        </strong>
        {deadline_date && format(new Date(deadline_date), 'dd/MM/yyyy')}
      </p>
      {isAssigned && (
        <>
          <p>
            <strong>
Visit deadline:
            </strong>
            {end_date && format(new Date(end_date), 'dd/MM/yyyy')}
          </p>
          <p>
            <strong>
Auditor name:
            </strong>
            {auditor_name}
          </p>
        </>
      )}
      <p>
        <strong>
Visit info:
        </strong>
        {visit_info}
      </p>
      <div className={styles.auditorsContainer}>
        <strong>
Last 3 Auditors:
        </strong>
        <div className={styles.auditors}>
          {audit_history?.length > 0
            && audit_history.map(auditor => (
              <span
                key={auditor?.audit_id}
                className={auditor?.result === AUDIT_RESULT.PASS ? styles.success : styles.error}
              >
                <span style={{ marginRight: '5px' }}>
                  <i className={auditor?.result === AUDIT_RESULT.PASS ? 'check icon' : 'times icon'} />
                </span>
                {auditor?.auditor_name}
,
                {auditor?.date_of_visit && format(new Date(auditor?.date_of_visit), 'dd/MM/yyyy')}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AuditContent;

AuditContent.propTypes = {
  content: PropTypes.shape({
    audit_history: PropTypes.arrayOf(
      PropTypes.shape({
        audit_id: PropTypes.string,
        auditor_name: PropTypes.string,
        date_of_visit: PropTypes.string,
        result: PropTypes.string,
      }),
    ),
    end_date: PropTypes.string,
    item_to_order: PropTypes.string,
    object: PropTypes.shape({
      status: PropTypes.string,
    }),
  }).isRequired,
};

import { AUDITOR_AGE_RANGES, AUDITOR_TYPES } from '../../components/pages/Map/helpers';

export default {
  googleMapFeature: {
    enabled: false,
    showAuditors: false,
    auditorFilters: {
      ageRanges: [...Object.values(AUDITOR_AGE_RANGES)],
      patches: [],
      types: [...Object.values(AUDITOR_TYPES)],
    },
  },
};
